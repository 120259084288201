import React from "react";

import Layout from "modules/common/components/layout";
import SEO from "modules/common/components/seo";
import Section from "modules/common/components/section";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Dan Simmons-Ritchie`, `Journalism`, `Engineer`, `Portfolio`]}
        title="About"
      />
      <Section className="text-left">
        <p>This is the portfolio website of Dan Simmons-Ritchie.</p>
      </Section>
    </Layout>
  );
}

export default AboutPage;
